(function() {
    angular.module('informaApp')
        .service('TableGroupsRoutes', TableGroupsRoutes);

    function TableGroupsRoutes() {
        const createRoute = (id, route) => ({ id, route });

        return {
            all: createRoute(0, 'all'),
            diseases: createRoute(1, 'diseases'),
            subDiseases: createRoute(2, 'subdiseases'),
            indications: createRoute(3, 'indications'),
            companies: createRoute(4, 'companies'),
            drugClasses: createRoute(5, 'drugclasses'),
            molecules: createRoute(6, 'molecules'),
            targets: createRoute(7, 'targets'),
            drugs: createRoute(8, 'drugs'),
            routes: createRoute(9, 'routes')
        };
    }
})();
